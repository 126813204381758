
export default {
  name: 'HomePageFourIcons',

  props: {
    api: {
      type: Object,
      default: null,
    },
  },
}
